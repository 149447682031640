<template>
    <Filters ref="filtersPanel" :showInstallerFields="false" :admin="false" />
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10">
      <div v-if="installations.data && installations.data.length" class="pb-16">
        <Pagination :pagination="installations.pagination" />
        <div class="space-y-3.5">
          <div v-for="inst in installations.data" :key="inst.id" class="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-ifgreen">
            <div class="flex-1 min-w-0">
                <router-link :to="`/installations/${ inst.id }`" class="focus:outline-none flex items-center	justify-between">
                  <span class="absolute inset-0" aria-hidden="true" />
                  <div class="w-3/4">
                    <p class="text-sm font-medium text-gray-900">
                      {{ inst.customerName }}
                    </p>
                    <p class="text-sm text-gray-500 truncate">
                      {{ inst.customerAddress }}, {{ inst.customerCity }}
                    </p>
                  </div>
                  <div class="flex flex-col items-end flex-shrink-0 w-1/4">
                    <p class="text-sm">
                      {{ new Date(inst.installationDate).toLocaleDateString('pl-PL') }}
                    </p>
                  </div>
                </router-link>
              </div>
              <div v-if="inst.installer.id !== user.id" :title="$t('installation.installationShared')" class="absolute -right-2 -bottom-2 rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                <ArrowDownOnSquareIcon class="h-5 w-5" aria-hidden="true" />
                <div class="whitespace-nowrap text-xs text-gray-900 rounded-xl px-1 h-4 bg-white absolute border border-gray-300 right-3/4 bottom-0">
                  {{ inst.installer.company }}
                </div>
              </div>
              <button v-else :title="$t('installation.shareInstallation')" @click="openShareModal(inst)" class="absolute -right-2 -bottom-2 rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                <ArrowUpOnSquareIcon class="h-5 w-5" aria-hidden="true" />
                <div v-if="inst.receivers && inst.receivers.length" class="text-xs text-gray-900 rounded-full w-4 h-4 bg-white absolute border border-gray-300 -left-1 -bottom-1">
                  {{ inst.receivers.length }}
                </div>
              </button>
          </div>
        </div>
        <Pagination :pagination="installations.pagination" />
      </div>
      <div v-else class="pt-4">
        <p v-if="installations.query">{{$t("public.infos.noInstallationFound")}}</p>
        <p v-else>{{$t("public.infos.noInstallationsYet")}}</p>
      </div>
    </div>
    <div class="fixed bottom-0 right-0 w-full p-4 flex space-x-4 z-10 bg-white">
      <button @click="openFilters" type="button" class="w-1/2 inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
        <MagnifyingGlassIcon class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
        {{ $t('search') }}
      </button>
      <button v-on:click="openModal" type="button" class="w-1/2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
          <PlusIcon class="h-5 w-5 mr-0.5" />
          {{$t("installation.addInstallation")}}
      </button>
    </div>
    <EditInstallation ref="addinst" />
    <ShareInstallation ref="shareinst" />

    <Modal
      ref="noAspModal"
      :title="$t('installation.selectAsp')"
      :text="$t('public.infos.aspInf')"
      :cancel-button-label="$t('notNow')"
    >
      <button
        type="button" class="modal-action-button"
        @click="chooseAsp"
      >
        {{$t("installation.selectAsp")}}
      </button>
    </Modal>
</template>
<script>
import { PlusIcon, ArrowUpOnSquareIcon, ArrowDownOnSquareIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import SearchBar from '@/components/inputs/SearchBar.vue';
import EditInstallation from '@/components/installations/EditInstallation.vue';
import ShareInstallation from '@/components/installations/ShareInstallation.vue';
import Modal from '@/components/layout/Modal.vue'
import Filters from '@/components/layout/Filters.vue';
import Pagination from '@/components/layout/Pagination.vue';
import { mapState } from 'vuex'
import { api } from "@/assets/js/api-client.js";

export default{
    components: {
      PlusIcon,
      SearchBar,
      EditInstallation,
      ShareInstallation,
      ArrowUpOnSquareIcon,
      ArrowDownOnSquareIcon,
      MagnifyingGlassIcon,
      Modal,
      Filters,
      Pagination
    },
    data () {
    },
    computed: {
        ...mapState(['user', 'installations', 'role']),
    },
    async created () {
      this.$store.dispatch('setLoading', true)
      await this.$store.dispatch('getCollection', {entity: 'installations', query: ''})
      console.log(this.installations)
      this.$store.dispatch('setLoading', false)
    },
    mounted () {
      if (this.$route.hash === '#new') {
        this.$refs.addinst.show()
      }
    },
    methods: {
      openFilters(){
        this.$refs.filtersPanel.show()
      },
      async openModal(){
        if (await this.$store.dispatch('checkAsp')) return
        api.get('/user').then(() => {
          this.$refs.addinst.show()
        }).catch(() => {
          this.$notify({
            title: `${this.$t('public.infos.noInternetConnection')}`,
            text: `${this.$t('public.infos.addingInstallationOffline')}`,
            type: 'warning',
            duration: 5000
          })
        })
      },
      openShareModal(inst){
        this.$store.dispatch('saveCurrentInstallation', inst)
        this.$refs.shareinst.show()
      }
    }
};

</script>
<style>
    .top-63x{
        top: 63px;
    }
</style>
